html {
  font-family: 'Proxima Nova', 'proxima-nova', 'Roboto', 'Open Sans', 'Helvetica', sans-serif;
  display: flex;
  height: 100%;
}
body {
  display: flex;
  flex: 1;
  margin: 0;
}
#root {
  flex: 1;
  display: flex;
}

/** {*/
/*  outline: green solid 3px*/
/*}*/
